body {
  /*background-color: #4985b3;*/
  /*background-image: linear-gradient(to bottom right, #4985b3, #0c293e);*/
  background: linear-gradient( hsla(206, 42%, 49%, 1) 34%, hsla(277, 79%, 84%, 1) 100%);
  color: #efefd0;
}

pre {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.vertical-center {
  min-height: 90%; /* Fallback for browsers do NOT support vh unit */
  min-height: 90vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.link {
  color: #efefd0;
}

.card {
  background-color: #f1f1df;
}

.link_card {
  color: #004e89;
}

#dropdownMenuButton1 {
  background-color: #f7c59f;
  color: #004e89;
}

.dropdown-menu {
  background-color: #f1f1df;
}

.recipe_name {
  background-color: #f1f1df;
  color: #004e89;
}

.ingredient_text {
  background-color: #f1f1df;
  color: #004e89;
}

.edit-btn {
  border: 3px solid #ececb0;
  color: #ececb0;
  font-size: 1.2rem;
}

.edit-btn:hover {
  color: #ff6b35;
  background: #ececb0;
}

.delete-btn {
  border: 3px solid #ff6b35;
  color: #ff6b35;
  font-size: 1.2rem;
}

.delete-btn:hover {
  color: #f1f1df;
  background: #ff6b35;
}

.delete-btn-user {
  border: 3px solid #8699a8;
  color: #ff6b35;
  font-size: 1.2rem;
}

.delete-btn-user:hover {
  color: #f1f1df;
  background: #ff353f;
}
.edit-btn-user {
  border: 3px solid #8699a8;
  color: #ff6b35;
  font-size: 1.2rem;
}

.edit-btn-user:hover {
  color: #f51f1f;
  background: #69da94;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.scrollable-place {
  height: 1000px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.table-info{
  --bs-table-color: #ff6b35;
  --bs-table-bg: #f1f1df;
  --bs-table-border-color: #004e89;
  --bs-table-striped-bg: #ececce;
  --bs-table-striped-color: #ff6b35;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #004e89;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #004e89;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info th{
  color: #004e89;
}